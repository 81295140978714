const faqs = [
  {
    question: "Recomendaciones para viajar durante días festivos",
    answer: (
      <div>
        <p>-  Planificar con anticipación: Piensa en tu viaje con suficiente antelación para reservar vuelos y alojamiento. En los días festivos, la demanda es alta, así que organiza todo con anticipación para asegurar disponibilidad. Los días de descanso obligatorio 2023 son: </p><br/>

        <p>- Domingo 1 de enero: Año nuevo </p>
        <p>- Lunes 6 de febrero: Día de la Constitución Mexicana (primer puente del 2023) </p>
        <p>- Lunes 20 de marzo: Natalicio de Benito Juárez (segundo puente del año)</p>
        <p>- Lunes 1 de mayo: Día internacional del trabajo</p>
        <p>- Sábado 16 de septiembre: Día de la independencia de México</p>
        <p>- Lunes 20 de noviembre: Aniversario de la Revolución Mexicana </p>
        <p>- Lunes 25 de diciembre: La celebración de la navidad (quinto puente del 2023)</p> <br/>
        
        <p>- Investigar el destino: Antes de ir a un lugar nuevo, busca información para saber cómo celebran allí los días festivos. Revisa si hay eventos especiales como fiestas que puedan interesarte. Así puedes hacer planes para estar en el lugar adecuado en el momento correcto y disfrutar de todo lo que tiene para ofrecer.</p><br/>
        <p>-Documentos en orden: Asegúrate de tener contigo todos los papeles importantes, como tu pasaporte y otros documentos que puedas necesitar. Verifica con suficiente antelación que sean válidos durante el tiempo que estarás viajando.</p><br/>
        <p>- Aprovechar las ofertas: Durante los días de fiesta, suele haber descuentos especiales en shows y sitios donde comer. Puedes buscar ofertas en línea para disfrutar más actividades sin gastar tanto.</p><br/>
        <p>- Medios de pago: Lleva dinero en efectivo para tus gastos y si es posible también alguna tarjeta de crédito o débito. Antes de viajar, suele ser conveniente hablar con el banco para comunicar sobre tu viaje y que nos las bloquee por error, pensando que alguien más las está usando.</p><br/>
        <p>- Medicamentos: Si necesitas tomar medicinas, asegúrate de tenerlas contigo en el viaje. También es una buena idea llevar contigo un pequeño estuche de primeros auxilios. Este estuche tiene cosas como vendas para cuando te lastimas y medicinas para cuando te duele algo.</p><br/>
        <p>- Información de contacto: Haz copias de los documentos más importantes, como el pasaporte e identificación. Guarda esas copias en un lugar seguro. Si por alguna razón no tienes los papeles originales, las copias te ayudarán. También, mantén contigo los números de teléfono de personas que pueden asistirte si necesitas algo.</p><br/>
        <p>- Seguro de viaje: Contratar un seguro de viaje es conveniente porque brinda protección en situaciones imprevistas. Si enfrentas problemas médicos o retrasos, el seguro cubre los gastos, evitándote costos inesperados.</p><br/>
        <p>- Empacar todo lo necesario: Cuando prepares tu maleta, piensa en el clima del lugar y en lo que harás. Lleva ropa que sea adecuada para esos momentos. No te excedas llevando muchas cosas, solo lleva lo que realmente necesitas.</p><br/>
    
       
    
      </div>
    ),
  },

 
];

export default faqs;
